<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow :gutter="30">
      <aCol :span="12">
        <HayaDatePickerField
          label="Data"
          placeholder="Escreva..."
          fieldKey="date"
          :required="true"
          :form="form"
        />
      </aCol>

      <aCol :span="12">
        <HayaTextField
          label="Documento"
          placeholder="Escreva..."
          fieldKey="document_id"
          :required="false"
          :form="form"
        />
      </aCol>

      <aCol :span="24">
        <HayaTextField
          label="Descrição"
          placeholder="Escreva..."
          fieldKey="description"
          :required="false"
          :form="form"
        />
      </aCol>

      <aCol :span="12">
        <HayaSelectField
          label="Forma de recebimento"
          fieldKey="payment_method"
          :list="paymentMethods"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="12">
        <HayaSelectField
          label="Fornecedor"
          fieldKey="product_supplier_id"
          :list="
            productSupplier.list.map((item) => {
              item.label = `${item.id} - ${item.trading_name}`;
              item.value = item.id;
              return item;
            })
          "
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
          optionFilterProp="txt"
        />
      </aCol>

      <aCol :span="12">
        <HayaValueField
          label="Valor"
          placeholder="Escreva..."
          v-currency="{
            currency: 'BRL',
            distractionFree: true,
            valueAsInteger: false,
            precision: 2,
            autoDecimalMode: true,
            allowNegative: true,
          }"
          fieldKey="value"
          :required="true"
          :form="form"
        />
      </aCol>

      <aCol :span="12" :offset="12">
        <aButton
          class="btn"
          type="primary"
          block
          html-type="submit"
          :loading="loadingCreateTransaction"
        >
          CONFIRMAR
        </aButton>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaValueField from "@/components/general/fields/HayaValueField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaDatePickerField from "@/components/general/fields/HayaDatePickerField.vue";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "AddBankTransactionModal",
  props: {
    type: String,
    bankAccount: Object,
  },
  components: {
    HayaTextField,
    HayaSelectField,
    HayaDatePickerField,
    HayaValueField,
  },
  mixins: [productSupplierMixins, formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      paymentMethods: [],
      loadingCreateTransaction: false,
      productSupplierName: "",
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=14&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.paymentMethods = data.data.map((paymentMethod) => {
          return {
            label: paymentMethod.name,
            value: paymentMethod.name,
          };
        });
      });

    this.productSupplier.pagination.perPage = 500;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();
  },
  methods: {
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.bank_id = this.bankAccount.id;
        values.type = this.type;
        values.status = "Realizado";
        values.value = values.value ? values.value.replace(",", ".") : 0;
        console.log(err, values);
        if (!err) this.createTransaction(values);
      });
    },
    async createTransaction(payload) {
      this.loadingCreateTransaction = true;
      try {
        const data = await this.$hayaApi.post(`/banks-transactions`, payload);
        this.$emit("onCreateTransaction");

        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.bankAccount.id,
          module: "company-bank-accounts",
          action: "create-bank-transaction",
          description: `<b>${this.$store.state.userData.first_name} ${
            this.$store.state.userData.last_name
          }</b> adicionou  ${this.formatPricePtBr(payload.value)} de <b>${
            this.type === "credit" ? "CRÉDITO" : "DÉBITO"
          } MANUAL</b> ao banco <b>ID ${this.bankAccount.id} - ${
            this.bankAccount.company_name
          }</b>.`,
        });

        return data;
      } catch (e) {
        e;
      } finally {
        this.loadingCreateTransaction = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.btn
  background-color: #00bd9b
  border-color: #00bd9b
  padding: 10px 0
  height: auto
</style>
