<template>
  <aside>
    <section class="mb-20 white-box bank-infos">
      <aRow type="flex" :gutter="20">
        <aCol flex="40px">
          <aAvatar :size="50" :src="bankAccount.logo">
            <aIcon type="bank" />
          </aAvatar>
        </aCol>
        <aCol flex="auto" style="flex: 1 1">
          <div class="name">
            <aRow type="flex" justify="space-between">
              <aCol>
                <div class="upper truncate" style="width: 200px">
                  {{ bankAccount.name }}
                </div>
              </aCol>
              <aCol>
                <img
                  src="@/assets/images/dashboard/banks/info.png"
                  alt="info"
                  width="14"
                  class="c-pointer"
                  @click="viewBankAccountModal = true"
                />
                <img
                  src="@/assets/images/dashboard/banks/switch.png"
                  alt="switch"
                  width="14"
                  class="c-pointer ml-5"
                  @click="openSwitchBankAccountModal = true"
                />
              </aCol>
            </aRow>
          </div>
          <div class="account">
            Agência: <strong>{{ bankAccount.agency }}</strong>
            <aDivider type="vertical" /> Conta:
            <strong>{{ bankAccount.account }}</strong>
          </div>
          <div class="company truncate" style="width: 230px">
            {{ bankAccount.company.company_name }}
          </div>
        </aCol>
      </aRow>
    </section>

    <section class="white-box side-menu">
      <div
        v-for="(item, i) in menuItems"
        :key="i"
        class="item"
        @click="onClickMenuItem(item.name)"
      >
        <img
          class="img"
          v-if="item.img"
          :src="item.img"
          :width="item.width"
          alt="img"
        />
        {{ item.name }}
      </div>
    </section>

    <section class="side-menu no-bg">
      <div
        v-for="(item, i) in subMenuItems"
        :key="i"
        class="item"
        @click="onClickMenuItem(item.name)"
      >
        <img
          class="img"
          v-if="item.img"
          :src="item.img"
          :width="item.width"
          alt="img"
        />
        {{ item.name }}
      </div>
    </section>

    <aModal
      class="banks-modal"
      :footer="false"
      :visible="openSwitchBankAccountModal"
      @cancel="openSwitchBankAccountModal = false"
    >
      <template #title>
        <aIcon type="bank" class="mr-5" /> Selecionar Banco
      </template>
      <SelectBankModal
        v-if="openSwitchBankAccountModal"
        :userIsVerified="userIsVerified"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </aModal>

    <aModal
      class="miles-card-modal"
      v-model="viewBankAccountModal"
      :width="1000"
      :footer="false"
      @close="viewBankAccountModal = false"
    >
      <CompanyBankAccountsModal
        :bankAccountId="bankAccount.id"
        v-if="viewBankAccountModal"
        @listcompanyBankAccountsAfterCreate="updateBankData"
      />
    </aModal>

    <aModal
      class="banks-modal"
      :footer="false"
      :visible="openAddCreditModal"
      :width="450"
      @cancel="openAddCreditModal = false"
    >
      <template #title>
        <img
          class="mr-5"
          src="@/assets/images/dashboard/banks/add-debit.png"
          alt="img"
          width="15"
        />
        Adicionar Crédito Manual
      </template>
      <AddBankTransactionModal
        v-if="openAddCreditModal"
        type="credit"
        :bankAccount="bankAccount"
        @onCreateTransaction="updateTransactionsList"
      />
    </aModal>

    <aModal
      class="banks-modal"
      :footer="false"
      :visible="openAddDebitModal"
      :width="480"
      @cancel="openAddDebitModal = false"
    >
      <template #title>
        <img
          class="mr-5"
          src="@/assets/images/dashboard/banks/minus-debit.png"
          alt="img"
          width="15"
        />
        Adicionar Débito Manual
      </template>
      <AddBankTransactionModal
        v-if="openAddDebitModal"
        type="debit"
        :bankAccount="bankAccount"
        @onCreateTransaction="updateTransactionsList"
      />
    </aModal>

    <aModal
      class="banks-modal"
      :footer="false"
      :visible="openSettingsModal"
      @cancel="openSettingsModal = false"
    >
      <template #title>
        <img
          class="mr-5"
          src="@/assets/images/dashboard/banks/settings.png"
          alt="img"
          width="15"
        />
        Configurações
      </template>
      ...
    </aModal>

    <aModal
      class="banks-modal"
      :footer="false"
      :visible="openLogsModal"
      @cancel="openLogsModal = false"
      :width="800"
    >
      <template #title>
        <img
          class="mr-5"
          src="@/assets/images/dashboard/banks/logs.png"
          alt="img"
          width="15"
        />
        Histórico
      </template>
      <LogsTimelineV2
        v-if="openLogsModal"
        :id="bankAccount.id"
        module="company-bank-accounts"
        color="green"
      />
    </aModal>
  </aside>
</template>

<script>
import SelectBankModal from "../modals/SelectBankModal.vue";
import CompanyBankAccountsModal from "../../company-bank-accounts/modal/CompanyBankAccountsModal.vue";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";

import balanceImg from "@/assets/images/dashboard/banks/update-balance.png";
import addDebitImg from "@/assets/images/dashboard/banks/add-debit.png";
import minusDebitImg from "@/assets/images/dashboard/banks/minus-debit.png";
import logImg from "@/assets/images/dashboard/banks/logs.png";
import AddBankTransactionModal from "../modals/AddBankTransactionModal.vue";

export default {
  name: "BankInfosSection",
  props: {
    bankAccount: Object,
    userIsVerified: Boolean,
  },
  components: {
    SelectBankModal,
    CompanyBankAccountsModal,
    LogsTimelineV2,
    AddBankTransactionModal,
  },
  data() {
    return {
      openSwitchBankAccountModal: false,
      viewBankAccountModal: false,
      openBalanceUpdateModal: false,
      openAddCreditModal: false,
      openAddDebitModal: false,
      openLogsModal: false,
      openSettingsModal: false,
      menuItems: [
        {
          name: "Atualizar Saldo",
          img: balanceImg,
          width: 15,
        },
        {
          name: "Adicionar Crédito Manual",
          img: addDebitImg,
          width: 15,
        },
        {
          name: "Adicionar Débito Manual",
          img: minusDebitImg,
          width: 15,
        },
      ],
      subMenuItems: [
        {
          name: "Histórico",
          img: logImg,
          width: 13,
        },
      ],
    };
  },
  methods: {
    onClickMenuItem(name) {
      if (name === "Atualizar Saldo") this.updateTransactionsList();
      if (name === "Adicionar Crédito Manual") this.openAddCreditModal = true;
      if (name === "Adicionar Débito Manual") this.openAddDebitModal = true;
      if (name === "Histórico") this.openLogsModal = true;
      if (name === "Configurações") this.openSettingsModal = true;
    },
    updateTransactionsList() {
      this.$emit("updateTransactionsList");
      this.openAddCreditModal = false;
      this.openAddDebitModal = false;
    },
    updateBankData() {
      this.$emit("updateBankData");
    },
    verificationSuccessfully(bankId, verificationData) {
      this.openSwitchBankAccountModal = false;
      this.$emit("verificationSuccessfully", bankId, verificationData);
    },
  },
};
</script>

<style lang="sass" scoped>
.white-box
  background: #fff
  padding: 15px
  border-radius: 8px

.side-menu.no-bg
  padding: 10px 15px
  .item
    border-bottom: 1px solid #d7d7d7
    .img
      margin-right: 5px

.side-menu
  .item
    padding: 10px
    border-bottom: 1px solid #d7d7d7
    display: flex
    align-items: center
    font-size: 13px
    transition: .3s
    cursor: pointer
    color: #595959
    .img
      margin-right: 5px
  .item:hover
    background: rgba(0,0,0,0.03)
  .item:last-child
    border-bottom: 0

.bank-infos
  color: #595959
  .name
    font-size: 14px
    font-weight: 600
  .account
    font-size: 10px
    color: #666
  .company
    font-size: 10px
    color: #666
</style>
