<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow :gutter="10">
      <aCol :span="22">
        <HayaSelectField
          fieldKey="company_bank_account_id"
          label="Banco"
          :required="true"
          :list="
            companyBankAccounts.list.map((item) => {
              return {
                label: `<img class='mr-10' src='${item.logo}' style='width: 20px; height: 20px' alt='logo' />  ${item.company_name}<span class='f10 cgray ml-10'> Agência. ${item.agency} | Conta: ${item.account}</span>`,
                value: item.id,
              };
            })
          "
        />
      </aCol>
      <aCol :span="2">
        <aButton
          type="primary"
          shape="circle"
          icon="right"
          html-type="submit"
        />
      </aCol>
    </aRow>

    <aModal
      :width="300"
      :footer="false"
      :visible="openPasswordModal"
      @cancel="openPasswordModal = false"
    >
      <template #title> <aIcon type="lock" /> Verificação </template>
      <LoginFormModal
        v-if="openPasswordModal"
        :updateStorageData="false"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </aModal>
  </aForm>
</template>

<script>
import companyBankAccountsMixins from "@/mixins/company-bank-accounts/companyBankAccountsMixins.js";
import HayaSelectField from "../../general/fields/HayaSelectField.vue";
import LoginFormModal from "../../login/LoginFormModal.vue";

export default {
  name: "SelectBankModal",
  props: {
    userIsVerified: Boolean,
  },
  components: { HayaSelectField, LoginFormModal },
  mixins: [companyBankAccountsMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      openPasswordModal: false,

      bankId: "",
    };
  },
  mounted() {
    this.companyBankAccounts.filters.status.selected = "Ativo";
    this.companyBankAccounts.pagination.perPage = 50;
    this.getCompanyBankAccounts();
  },
  methods: {
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.bankId = values.company_bank_account_id;
          if (!this.userIsVerified) this.openPasswordModal = true;
          if (this.userIsVerified)
            this.$emit("verificationSuccessfully", this.bankId, {});
        }
      });
    },
    verificationSuccessfully(verificationData) {
      this.openPasswordModal = false;
      this.$emit("verificationSuccessfully", this.bankId, verificationData);
    },
  },
};
</script>
