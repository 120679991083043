var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('aRow',{attrs:{"gutter":30}},[_c('aCol',{attrs:{"span":12}},[_c('HayaDatePickerField',{attrs:{"label":"Data","placeholder":"Escreva...","fieldKey":"date","required":true,"form":_vm.form}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('HayaTextField',{attrs:{"label":"Documento","placeholder":"Escreva...","fieldKey":"document_id","required":false,"form":_vm.form}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaTextField',{attrs:{"label":"Descrição","placeholder":"Escreva...","fieldKey":"description","required":false,"form":_vm.form}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('HayaSelectField',{attrs:{"label":"Forma de recebimento","fieldKey":"payment_method","list":_vm.paymentMethods,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('HayaSelectField',{attrs:{"label":"Fornecedor","fieldKey":"product_supplier_id","list":_vm.productSupplier.list.map((item) => {
            item.label = `${item.id} - ${item.trading_name}`;
            item.value = item.id;
            return item;
          }),"required":true,"form":_vm.form,"allowClear":true,"showSearch":true,"optionFilterProp":"txt"}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('HayaValueField',{directives:[{name:"currency",rawName:"v-currency",value:({
          currency: 'BRL',
          distractionFree: true,
          valueAsInteger: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: true,
        }),expression:"{\n          currency: 'BRL',\n          distractionFree: true,\n          valueAsInteger: false,\n          precision: 2,\n          autoDecimalMode: true,\n          allowNegative: true,\n        }"}],attrs:{"label":"Valor","placeholder":"Escreva...","fieldKey":"value","required":true,"form":_vm.form}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":12,"offset":12}},[_c('aButton',{staticClass:"btn",attrs:{"type":"primary","block":"","html-type":"submit","loading":_vm.loadingCreateTransaction}},[_vm._v("\n        CONFIRMAR\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }